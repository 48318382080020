import $ from "jquery";
import "jquery.scrollto";

$(function() {
    $(".bl-herohome .icon-scrolldown").on("click", function() {
        const $scrolldownindicator = $(this);
        const $herostart = $scrolldownindicator.closest(".bl-herohome");
        const $nextElement = $herostart.next();
        $(window).scrollTo($nextElement, 500);
    });
});

